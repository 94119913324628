//
// Layouts
//

// Navbar

.navbar-bg {
	position: absolute;
	top: 0;
	width: 100%;
	height: 1400px;
	background: $body-bg;
	background: linear-gradient(
		180deg,
		rgba($teal-300, .25) 0%,
		$body-bg 100%
	);
	z-index: -1;
}

.navbar {
	.navbar-brand {
		margin-left: auto;
		margin-right: auto;
		padding-left: $navbar-toggler-width;
		color: $body-color;
	}
}

.navbar-collapse {
	padding-top: $navbar-padding-y;
	padding-bottom: $navbar-padding-y;
}

.navbar-nav {
	.nav-item {
		margin-bottom: $paragraph-margin-bottom * .5;
		
		&:last-child {
			margin-bottom: 0;
		}
	}
	
	.nav-link {
		text-align: center;
		background-color: $primary;
		border: solid 3px $primary;
		border-radius: $border-radius-pill;
		
		&:not(:hover) {
			color: $white;
		}
		
		&:hover {
			color: $primary;
			background-color: rgba($white, .5);
		}
	}
}

@include media-breakpoint-up(md) {
	.navbar {
		padding-top: $navbar-padding-y * 2;
		padding-bottom: $navbar-padding-y * 2;
	}
	
	.navbar-toggler {
		display: none;
	}
	
	.navbar-collapse {
		display: block !important;
		flex-basis: auto;
		flex-grow: inherit;
		padding-top: 0;
		
		> .container,
		> .container-fluid {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}
	
	.navbar-nav {
		flex: 1 1 auto;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		
		.nav-item {
			flex: 1 1 auto;
			margin-bottom: 0;
			margin-right: $spacer * .5;
			
			&:last-child {
				margin-right: 0;
			}
		}
	}
}

// Footer

.footer {
	padding-top: $navbar-padding-y;
	padding-bottom: $navbar-padding-y;
	color: $white;
	background-color: $primary;
	@include font-size($font-size-sm);
}

.credit {
	text-align: center;
}

.pagetop {
	position: fixed;
	bottom: 0;
	right: 0;
	margin: $grid-gutter-width * .5;
	z-index: $zindex-fixed;
	
	a {
		display: inline-block;
		width: 50px;
		height: 50px;
		line-height: 50px;
		text-align: center;
		text-decoration: none;
		color: $white;
		background-color: $primary;
		@include border-radius($border-radius-pill);
	}
}

// Content

.content {
	padding-top: $grid-gutter-width;
	padding-bottom: $grid-gutter-width;
}

.main,
.side {
	@include make-col-ready();
}

.main {
	margin-bottom: $grid-gutter-width;
}

@include media-breakpoint-up(md) {
	.content {
		padding-top: $grid-gutter-width * 2;
		padding-bottom: $grid-gutter-width * 2;
	}
	
	.main {
		margin-bottom: 0;
		@include make-col(8, $grid-columns);
	}
	
	.side {
		@include make-col(4, $grid-columns);
	}
}

@include media-breakpoint-up(xl) {
	.main {
		@include make-col(9, $grid-columns);
	}
	
	.side {
		@include make-col(3, $grid-columns);
	}
}
